
import {defineComponent, onMounted, onUpdated} from "vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "Analytics",
  setup() {
    onUpdated(() => {
      const link = document?.getElementById("main-overview")
      console.log(link);
      if (link) {
        link.classList.remove("active")
      }
    })
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams("Analytics", [])
      const link = document?.getElementById("main-overview")
      if (link) {
        link.classList.remove("active")
      }
    })
  }
})
